import React, { useMemo } from 'react';
import styled, {css} from 'styled-components';
import SbEditable from 'components/SbEditableExtended';

import WhiteBox from 'components/blocks/blockPartials/whiteBox';
import ContentArea from 'components/contentArea';

import calculateBottomSpacingClass from 'components/blocks/utils/calculateBottomSpacingClass';


// Component
const Component = (props) => {

  const spacingBottomClass = useMemo(() => calculateBottomSpacingClass(props.block?.bottom_spacing), [props.block?.bottom_spacing]);

  if(!props.block){
    return(<div>Storyblok block missing</div>);
  }

	return(
    <SbEditable content={props.block}>
      <StyledComponent {...props} className={`blockWhiteBox ${spacingBottomClass}`}>
        <WhiteBox>
          {props.block && props.block.content && 
            <ContentArea content={props.block.content} />
          }
        </WhiteBox>
      </StyledComponent>
    </SbEditable>
	);

}

export default Component;


// Styles
const StyledComponent = styled.div``;